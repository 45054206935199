/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.5.1/dist/jquery.slim.min.js
 * - /npm/popper.js@1.16.1/dist/umd/popper.min.js
 * - /npm/bootstrap@4.5.0/dist/js/bootstrap.min.js
 * - /npm/masonry-layout@4.2.2/dist/masonry.pkgd.min.js
 * - /npm/packery@2.1.2/dist/packery.pkgd.min.js
 * - /npm/imagesloaded@5.0.0/imagesloaded.pkgd.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
